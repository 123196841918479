import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth_keycloak/auth.service';
import { KeycloakService } from 'keycloak-angular'
import { AuthLocalService } from '../auth_local/auth-local.service';
import { AuthPermissionService } from '../service/auth-permission.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  mobile: boolean = false;
  constructor(
    private keyCloakServ: KeycloakService,
    private authService: AuthService,
    private authLocalService: AuthLocalService,
    private authPremissServ: AuthPermissionService,
    private router: Router,
  ) { }

  public user_role: any = false;
  public user;
  public privilege;

  ngOnInit(): void {
    if (window.screen.width <= 414) { }
    else { this.stickyNavbar(); }
    this.keyCloakRegisterLocalServ();
    setTimeout(() => {
      this.user_role = this.authPremissServ.checkRole(localStorage.getItem("usty"));;
      this.user = localStorage.getItem("usnm");
      this.privilege = this.user !== null;
    }, 1000);
    
  }

  stickyNavbar() {
    $(window).scroll(function () {
      let targetScroll = $('.header-line-hight-top').position().top + $('.header-line-hight-top').outerHeight(true);
      if ($(window).scrollTop() <= targetScroll) {
        $(".sticky-top").removeClass("nav-fixedtop");
      } else {
        $(".sticky-top").addClass("nav-fixedtop");
      }
    });
  }

  logOut() {
    let guard_type = localStorage.getItem("guard_type");
    if (guard_type == "guard-local") {
      this.authLocalService.deauthen();
    } else {
      this.authService.deauthen();
    }
  }

  public logInForm() {
    // HIDE TO LOGIN LOCAL PAGE
    // let redirect_url = this.router.url;
    // localStorage.setItem('redirect_url', redirect_url);
    // this.router.navigate(['/login']);
    this.keyCloakServ.login();
  }

    /**
   * set system login with keycloak
   */
    public keyCloakRegisterLocalServ() {
      this.keyCloakServ.isLoggedIn().then(
        (res) => {
          if (res) { 
            this.authService.initializeUserOptions(); 
          }else{
            this.authService.clearStorage();
          }
        }
      );
    }

}
