import { HttpHeaders,HttpClient } from '@angular/common/http';

export const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'token': ''
    })
};


export const httpOptionsPost = {
  headers: new HttpHeaders({
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Headers': 'SOAPAction,Content-Type',
    // 'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, OPTIONS, DELETE, POST,GET,OPTION',
    // 'Access-Control-Allow-Origin': '*',
    // 'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'token': ''
  })
};