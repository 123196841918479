import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { HttpHeaders,HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { CoreService, StorageService, ConfigService } from '../service';

export interface authen {
  gid:number;
  uid:number;
  valid:boolean;
  token_valid:boolean;
};



@Injectable({
  providedIn: 'root'
})
export class AuthLocalGuard implements CanActivate {

  constructor (
    private http: HttpClient,
    
    private core: CoreService,
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true|UrlTree  {
      const url: string = state.url;
      return this.checkLogIn(url,route);
  }

  canActivateChild(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): true|UrlTree {
    return this.canActivate(route, state);
  }

  checkLogIn(url:string,route): true|UrlTree {

    const requiredRoles = route.data.roles;
    let allow;
    if(this.checkAuthen()){
      allow = true;
      console.log("checkAuthen1");
    }else{
      console.log("checkAuthen2");
      if(requiredRoles[0] === "public"){
        allow = true
      }else{
        allow = false
      }
    }
    return allow
  }


  checkLogServ(id, type) {
    let url = this.core.getApiUrl(`/security/checkuser/${id}/${type}`);
    return this.http.get<authen>(url);
  }

  checkAuthen()  {
    let chk;
    if(this.storage.checkLocal()){
      this.storage.setSession(localStorage.getItem('usnm'),localStorage.getItem('usid'),localStorage.getItem('usty'),localStorage.getItem('ustk'));
    }
    if(this.storage.checkSession()){
      chk = this.checkLogServ(sessionStorage.getItem('usid'),sessionStorage.getItem('usty')).subscribe(
        (authen)=>{  
          if(authen.token_valid == false){   
            // this.router.navigate(['/login']); 
        }
        });
    }
    return chk;
  }

  
}
