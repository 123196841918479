<div class="container  min-vh-100">
  <div class="d-flex justify-content-center min-vh-100" style="background-image: url('assets/images/bg-wrapper-p.png');">
    <div class="user_card">
      <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img src="assets/images/cubems_cham5_logo.png" class="brand_logo" alt="Logo">
        </div>
      </div>
      <div class="d-flex justify-content-center form_container">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="input-group mb-3">
            <div class="input-group-append">
              <span class="input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                </svg>
              </span>
            </div>
            <input type="text" id="userName" class="form-control" type='text' formControlName="username" placeholder="username">
          </div>
          <div class="input-group mb-2">
            <div class="input-group-append">
              <span class="input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
                  <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
                  <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </span>
            </div>
            <input id="userName" class="form-control" type='password' formControlName="password" placeholder="password">
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customControlInline" formControlName="remember">
              <label class="custom-control-label" for="customControlInline">Remember me</label>
            </div>
          </div>
            <div class="alert alert-warning" role="alert" *ngIf="invalid === true">{{ msg }}</div>
            <div class="d-flex justify-content-center mt-3 login_container">
              <button type='submit' name="button" class="btn login_btn">Login</button>
            </div>
        </form>
      </div>
      <!--   
      <div class="mt-4">
        <div class="d-flex justify-content-center links">
          Don't have an account? <a href="#" class="ml-2">Sign Up</a>
        </div>
        <div class="d-flex justify-content-center links">
          <a href="#">Forgot your password?</a>
        </div>
      </div> -->
    </div>
  </div>
</div>
<!-- <div class="container-fluid min-vh-100">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row col-6 card">
          <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <div class="form-group">
                <label for="userName">Username</label>
                <input id="userName" class="form-control" type='text' formControlName="username">
              </div>
              <div class="form-group">
                <label for="passWord">Password</label>
                <input id="userName" class="form-control" type='password' formControlName="password">
              </div>
              <label>
                <input type="checkbox" formControlName="remember">
                &nbsp;
                <span>Remember
                  <span class='visible-lg-inline-block'>Me</span>
                </span>
              </label>
              <br/>
              <div class="alert alert-warning" role="alert" *ngIf="invalid === true">{{ msg }}</div>
              <button class="btn btn-primary" type='submit'> Log In </button>
            </form>
          </div>
        </div>
      </div> -->
        <!-- <div class="Absolute-Center is-Responsive">
          <div class="col-sm-12 col-md-6 col-md-offset-1">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <div class="form-group">
                <label for="userName">Username</label>
                <input id="userName" class="form-control" type='text' formControlName="username">
              </div>
              <div class="form-group">
                <label for="passWord">Password</label>
                <input id="userName" class="form-control" type='password' formControlName="password">
              </div>
              <label>
                <input type="checkbox" formControlName="remember">
                &nbsp;
                <span>Remember
                  <span class='visible-lg-inline-block'>Me</span>
                </span>
              </label>
              <br/>
              <div class="alert alert-warning" role="alert" *ngIf="invalid === true">{{ msg }}</div>
              <button class="btn btn-primary" type='submit'> Log In </button>
            </form>
          </div>
        </div> -->
    <!-- </div>
  </div>
</div> -->

