import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  hash = [
    'd00a47f97afcb665f9c189261e36e49a',
    '9dddd5ce1b1375bc497feeb871842d4b',
    '15de21c670ae7c3f6f3f1f37029303c9',
    '9cdfb439c7876e703e307864c9167a15',
    '4eae35f1b35977a00ebd8086c259d4c9'
  ];
  constructor() { }
  checkSession() {
    var chk;
    return (
      sessionStorage.getItem('pswd') !== null &&
      sessionStorage.getItem('usnm') !== null &&
      sessionStorage.getItem('usid') !== null &&
      sessionStorage.getItem('usty') !== null &&
      sessionStorage.getItem('ustk') !== null
    );
  }

  checkLocal() {
    return (
      localStorage.getItem('pswd') !== null &&
      localStorage.getItem('usnm') !== null &&
      localStorage.getItem('usid') !== null &&
      localStorage.getItem('usty') !== null &&
      localStorage.getItem('ustk') !== null
    );
  }

  setSession(usnm, usid, usty, token) {
    sessionStorage.setItem('pswd',this.hash[Math.floor(Math.random() * 5 + 1) - 1]);
    sessionStorage.setItem('usnm',usnm);
    sessionStorage.setItem('usid',usid);
    sessionStorage.setItem('usty',usty);
    sessionStorage.setItem('ustk',token);
  }

  setLocal() {
    localStorage.setItem('pswd',sessionStorage.getItem('pswd'));
    localStorage.setItem('usnm',sessionStorage.getItem('usnm'));
    localStorage.setItem('usid',sessionStorage.getItem('usid'));
    localStorage.setItem('usty',sessionStorage.getItem('usty'));
    localStorage.setItem('ustk',sessionStorage.getItem('ustk'));
  }

  clears() {
    localStorage.clear();
    sessionStorage.clear();
  }

}
