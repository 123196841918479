import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  dataHeadTiltle = '';
  dataSubTitle = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      this.dataHeadTiltle = this.routerInit();
      this.dataSubTitle = this.routerInit();
  }
  

  ngOnInit(): void {
  }

  routerInit(): any{
    this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    )
    .subscribe(() => {

      var rt = this.getChild(this.activatedRoute)

      rt.data.subscribe(data => {
        this.dataHeadTiltle = data.headTitle;
        this.dataSubTitle = data.subTitle;
      })
    })
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
