import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './auth_keycloak/auth.guard';
import { AuthLocalGuard } from './auth_local/auth-local.guard';

let guard_type = localStorage.getItem('guard_type');
let guard_aerobase = [AuthGuard];
let guard_local = [AuthLocalGuard];
let switch_guard;

if (guard_type == null) {
  switch_guard = guard_local;
} else if (guard_type == 'guard-local') {
  switch_guard = guard_local;
} else {
  switch_guard = guard_aerobase;
}

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: { roles: ["public"]}
  },
  {
    path: 'owner',
    loadChildren: () =>
      import('./owner/owner.module').then((m) => m.OwnerModule),
    canActivate: [AuthGuard],
    data: { roles: ["owner"]}
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: { roles: ["admin"]}
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
