/* External Dependencies */
import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import { Router } from '@angular/router';

/* Internal Dependencies */
import { AuthService } from '../auth.service';
import { MessageDialogService } from './../../service/message-dialog.service';

export interface authen {
  gid:number;
  token:string;
  uid:number;
  valid:boolean;
};
export interface key {
  bsid: string;
  pk: number[];
};

declare var RSAKey: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {


  constructor(
    public authService: AuthService, 
    public router: Router, 
    private msgDialog: MessageDialogService
    ) {
  }

  msg:string;
  invalid:boolean = false;
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    remember: new FormControl(false)
  });

  ngOnInit(): void {
    
  }


  login() {
    
    let username = this.loginForm.value.username;
    let password = this.loginForm.value.password;
    let remember = this.loginForm.value.remember;

    if(username === '' && password ===''){
      this.invalid = true;
      this.msg = this.msgDialog.msgInputText('Username or Password are not correct.');
    }else if(username === ''){
      this.invalid = true;
      this.msg = this.msgDialog.msgInputText('Username are not correct.');
    }else if(password === ''){
      this.invalid = true;
      this.msg = this.msgDialog.msgInputText('Password are not correct.');
    } else {
      this.invalid = false;
      this.authService.setKey(username,password,remember)
    }
  }
  
 


}
