import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

import { AuthService } from '../auth_keycloak/auth.service';
import { CoreService, StorageService, ConfigService } from '../service';


@Injectable({
  providedIn: 'root'
})
export class AuthPermissionService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private core: CoreService,
    private storag: StorageService,
    private authService: AuthService,
    private keyCloakServ: KeycloakService
  ) { }

  public checkRole(role) {
    let new_role: any;
    if (role == "admin_d" || role == "admin_s") {
      new_role = 'admin';
    } else if (role == "pw_user") {
      new_role = 'power';
    } else if (role == "owner") {
      new_role = 'owner';
    } else {
      new_role = false;
    }
    return new_role;
  }

  private checkLocalServExpire() {
    let url = this.core.getApiUrl(`/users/profile`);
    return this.http.get(url);
  }

  private checkLocal(isLogin: boolean) {
    let aeroBase = isLogin;
    this.checkLocalServExpire().subscribe(
      (localServ: any) => {
        // console.log("aeroBase:",aeroBase);
        // console.log("localServ:",localServ);
        let local_token = localServ;
        if (aeroBase == false) {
          // aerobase = false
          if (local_token.token_valid === false) {
            // console.log("local serv หมดอายุ");
            this.clearLocalStorage();
            // this.router.navigate(['/home']);
          } else {
            // console.log("local serv ไม่หมดอายุ");ERROR LOOP LOGIN
            // this.router.navigate(['/owner']);
          }
        } else {
          // aerobase = ture
          if (local_token.token_valid === false) {
            // Register ระบบใหม่
            this.authService.initializeUserOptions();
          } else {
            // this.router.navigate(['/owner']);
          }
        }
      }
    );
  }

  public async checkPermissTokenKeycloak() {
    // console.log("checkPermissTokenKeycloak");
    this.keyCloakServ.isLoggedIn().then(
      (aerobase_login: any) => {
        console.log("isLoggedIn:", aerobase_login);
        if (aerobase_login == false) {
          this.router.navigate(['/owner']);
          this.checkLocal(false);
        } else {
          this.checkLocal(true);
        }
      }
    );
  }


  public clearLocalStorage() {
    localStorage.removeItem('pswd');
    localStorage.removeItem('usnm');
    localStorage.removeItem('usid');
    localStorage.removeItem('usty');
    localStorage.removeItem('ustk');

    sessionStorage.removeItem('pswd');
    sessionStorage.removeItem('usnm');
    sessionStorage.removeItem('usid');
    sessionStorage.removeItem('usty');
    sessionStorage.removeItem('ustk');
  }


}
