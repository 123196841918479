/* External Dependencies */
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { httpOptions } from './../header-options/header-option';
import { catchError, retry } from 'rxjs/operators';
import { tap, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';


/* Internal Dependencies */
import { CoreService, StorageService, ConfigService } from '../service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient,
    private core: CoreService,
    private storag: StorageService,
    private router: Router,
    private keycloakService: KeycloakService
  ) { }

  private loginServ(encrypt) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: encrypt })
    };
    let url = this.core.getApiUrl(`/security/authen`);
    return this.http.get(url, httpOptions);
  }

  private authen(username, passpharse) {
    this.loginServ(passpharse).subscribe(
      (res: any) => {
        this.authorize(username, 'remember', res.uid, res.gtp, res.token);
      },
      (err) => {
        console.log("err:", err);
      }
    );
  }

  private register_auth(user, key) {
    this.authen(user, key);
  }

  private authorize(username, remember, id, type, token) {
    this.storag.setSession(username, id, type, token);
    this.storag.setLocal();
  }

  public check_token_valid() {
    this.checkTokenServ().subscribe(
      (e: any) => {
        if (e.token_valid == false) {
          this.deauthen();
        } else {
        }
      }
    );
  }

  public checkTokenServ() {
    let url = this.core.getApiUrl(`/users/profile`);
    return this.http.get(url);
  }

  public check_role(role) {
    let role_check: any;
    if (role == "admin_d" || role == "admin_s") {
      role_check = 'admin';
    } else if (role == "pw_user") {
      role_check = 'power';
    } else if (role == "owner") {
      role_check = 'owner';
    } else {
      role_check = false;
    }
    return role_check;
  }

  public deauthen() {
    this.storag.clears();
    let url = this.router.url.split("/");
    let re_url;
    if(url[0] == 'lump_sum'){
      re_url = window.location.origin+'/lump_sum/';
    }else{
      re_url = window.location.origin;
    }
    let redirect_url: string = re_url;
    this.keycloakService.logout(redirect_url);
  }

  public clearStorage() {
    this.storag.clears();
  }

  public initializeUserOptions(): void {
    this.keycloakService.getToken().then((key) => {
      this.register_auth(this.keycloakService.getUsername(), key);
    });
  }
}
